﻿body {
    background: #222 url("/resources/images/bg-login.gif");
}
.field-validation-error {
    @extend .form-text;
    color: #ff4500;
}

.validation-summary-errors {
    @extend .alert;
    @extend .alert-danger;

    ul {
        margin-bottom: 0;
    }
}
